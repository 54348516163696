.footer {
  background-color: var(--dark-blue);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 20px 40px;
}

.footer .footer-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#footer-logo {
  height: 80px;
}

.copyright-text {
  font-size: 14px;
}

@media screen and (min-width: 900px) {
  #footer-logo {
		height: 92px;
  }
}