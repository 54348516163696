.gallery-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px 40px 160px;
}

.gallery-container h4 {
  font-size: 40px;
  margin-bottom: 30px;
  border-bottom: 3px solid var(--dark-blue);
}

.gallery-container h4:hover {
  cursor: pointer;
  text-shadow: 0.5px 0.5px rgba(0,0,0,0.7);
  /* font-weight: 450; */
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.95;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;  
  opacity: 1;
  transform: translate(-50%, -50%);
}

.placeholder-img {
  border: 2px solid var(--dark-blue);
  border-radius: 20px;
  max-height: 600px;
  cursor: pointer;
}

.placeholder-img:hover {
  box-shadow: 0.5px 0.5px rgba(0,0,0,0.7);
}

.modal-container .arrows img {
  height: auto;
  width: 24px;
  margin-top: 60px;
}

.modal-container .arrows,
.modal-container .modal-close-btn {
  display: block;
  position: absolute;
  font-size: 32px;
  color: var(--white);
  z-index: 2;
  cursor: pointer;
}

.modal-wrapper {
  position: relative;
}

.modal-container img {
  max-height: 800px;
}

.modal-container .modal-close-btn {
  position: fixed;
  right: -60px;
  top: -60px;
}

.modal-container .arrows {
  top: calc(50% - 80px);
}

.modal-container .left-arrow {
  left: -60px;
}

.modal-container .right-arrow {
  right: -60px;
}

@media screen and (max-width: 900px) {
  .placeholder-img {
    width: calc(100% - 20px);
  }

  .modal-container .modal-close-btn {
    right: 20px;
    top: -80px;
  }

  .modal-container .arrows img {
    margin-top: 60px;
  }
  
  .modal-container .left-arrow {
    left: 20px;
  }
  
  .modal-container .right-arrow {
    right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .gallery-container {
    padding: 40px 20px 120px;
  }

  .modal-container,
  .placeholder-img {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 450px) {
  .gallery-container {
    padding: 40px 10px 80px;
  }

  .modal-container,
  .placeholder-img {
    width: 100%;
  }
}
