.carousel-container img {
  display: block;
  width: 100%;
}

h1 {
  font-size: 56px;
  color: var(--white);
  margin-top: -120px;
  margin-bottom: 46px;
}

.carousel-img-0,
.carousel-img-1 {
  text-align: right;
  margin-right: 60px;
}

.carousel-img-2,
.carousel-img-3 {
  margin-left: 60px;
}


/*
  clean these media queries up by using
  max or min only where possible and by
  taking advantage of variables
*/

/* min-widths */

@media screen and (min-width: 1400px) {
  h1 {
    font-size: 64px;
    margin-top: -150px;
    margin-bottom: 66px;
  }

  .carousel-img-0,
  .carousel-img-1 {
    margin-right: 5%;
  }
  
  .carousel-img-2,
  .carousel-img-3 {
    margin-left: 5%;
  }
}

@media screen and (min-width: 2100px) {
  h1 {
    font-size: 80px;
    margin-top: -190px;
    margin-bottom: 86px;
  }
}

/* max-widths */

@media screen and (max-width: 900px) {
  h1 {
    font-size: 48px;
    margin-top: -128px;
    margin-bottom: 66px;
  }
}

@media screen and (max-width: 620px) {
  h1 {
    font-size: 48px;
    margin-top: -172px;
    margin-bottom: 48px;
  }

  .carousel-img-0,
  .carousel-img-1 {
    margin-right: 40px;
    margin-left: 60px;
  }

  .carousel-img-2,
  .carousel-img-3 {
    margin-left: 40px;
    margin-right: 60px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
    margin-top: -142px;
    margin-bottom: 26px;
  }

  .carousel-img-0,
  .carousel-img-1 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .carousel-img-2,
  .carousel-img-3 {
    margin-right: 30px;
    margin-left: 30px;
  }
}
