.services-container .content-box ul {
  margin: 10px 0 40px;
  padding: 0;
  font-size: 18px;
  text-decoration: none;
  list-style: none;
  line-height: 28px;
  text-align: center;
}

.services-container .content-box .service-title {
  text-align: center;
  font-weight: bold;
  color: var(--dark-blue);
  font-size: 24px;
  margin: 0;
}
