.content-box {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  min-height: 440px;
  align-items: center;
}

.content-left,
.content-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: calc(50% - 80px);
}

.content-box h4 {
  border-bottom: 3px solid var(--dark-blue);
  font-size: 40px;
  /* width: calc(50% - 40px); */
  margin: 20px 0;
  padding: 0 20px;
  text-align: center;
  white-space: nowrap;
}

.content-box p {
  opacity: 0.9;
  text-align: center;
}

.content-box img {
  height: auto;
  max-width: 800px;
  min-width: 400px;
  width: calc(100% - 40px);
  border-radius: 10px;
}

@media screen and (min-width: 1400px) {
  .content-box {
    padding: calc(2% + 40px);
  }
}

@media screen and (max-width: 900px) {
  .content-box {
    flex-direction: column;
    width: calc(100% - 80px);
    display: unset;
    padding: 40px;
  }

  .content-left,
  .content-right {
    width: calc(100% - 40px);
    margin: 40px 0;
  }
  
  .content-box h4 {
    margin: 0 auto 20px;
  }

  .content-box p {
    padding: 0 60px;
  }
}

@media screen and (max-width: 600px) {
  .content-left {
    margin-top: 0;
  }

  .content-right {
    margin-bottom: 0;
  }

  .content-box h4 {
    width: calc(100% - 80px);
  }

  .content-box img {
    width: 200px;
  }
}

@media screen and (max-width: 450px) {
  .content-box {
    padding-bottom: 0;
  }

  .content-left {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .content-box img {
    margin-bottom: -24px;
  }
}