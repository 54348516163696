@font-face {
  font-family: 'K2D-Regular';
  src: url('/src/assets/img/fonts/K2D/K2D-Regular.ttf');
}

@font-face {
  font-family: 'K2D-Bold';
  src: url('/src/assets/img/fonts/K2D/K2D-Bold.ttf');
}

@font-face {
  font-family: 'K2D-Light';
  src: url('/src/assets/img/fonts/K2D/K2D-Light.ttf');
}

:root {
  /* color vars */
  --black: #0f1010;
  --dark-blue: #214194;
  --grey: #C4C4C4;
  --beige: #ebe8d7;
  --light-beige: #f8f7f3;
  --white: #fffffe;

  /* create vars for media query widths */
}

body, html {
  background-color: var(--light-beige);
  font-family: 'K2D-Regular';
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.text-bold {
  font-family: 'K2D-Bold';
}

.text-center {
  text-align: center;
}

.text-light {
  font-family: 'K2D-Light';
}

.content-box-container:nth-child(odd) {
  background-color: var(--beige);
}

@media screen and (max-width: 900px) {
  body, html {
    overflow-x: hidden;
  }
}
