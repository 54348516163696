.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin: 20px auto 40px;
  text-align: center;
}

.contact-info span {
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .contact-info {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: left;
    font-size: 16px;
  }
  
  .contact-info p {
    margin-top: 0;
  }
  
  .contact-info span {
    text-align: left;
  }
}
