@media screen and (min-width: 1400px) {
  .about-container .content-box h4 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 900px) {
  .about-container .content-box h4 {
    margin-top: 0;
  }

  .about-container .content-box .content-right {
    padding-top: 0;
  }
}