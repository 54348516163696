nav {
	background-color: var(--dark-blue);
	height: 92px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 80px);
	align-items: flex-end;
	padding: 20px 40px; /* set as var so you can more easily set layouts? */
}

#logo {
	height: 92px;
	margin-left: -10px;
}

.mobile-nav-menu,
.mobile-nav-icon {
	display: none;
}

.mobile-nav-menu span {
	cursor: pointer;
}

#slogan {
	font-size: 15px;
}

.nav-links-container {
	font-size: 20px;
}

.nav-links-container span {
	margin-left: 40px;
	cursor: pointer;
}

@media screen and (max-width: 900px) {
	nav {
		width: calc(100% - 40px);
		padding: 20px;
		align-items: center;
	}
	.nav-links-container {
		display: none;
	}

	#logo {
		height: 92px;
		margin-left: -4px;
	}

	.mobile-nav-icon {
		display: block;
		margin-top: 4px;
		margin-right: 20px;
		height: 16px;
		cursor: pointer;
	}

	.mobile-nav-menu {
		display: block;
		position: absolute;
		top: 140px;
		right: 10px;
		background-color: var(--dark-blue);
		border-radius: 10px;
    padding: 20px 40px 20px 10px;
	}

	.mobile-nav-links-container {
		display: flex;
		flex-direction: column;
	}

	.mobile-nav-links-container span {
		margin: 10px 20px;
	}
}